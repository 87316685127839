import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PaginationDataType } from '@/types/PaginationType'

export type DeviceState = PaginationDataType<DeviceModel[]> & { device?: DeviceModel }

export enum EDeviceActions {
    FETCH_DEVICE = 'fetchDevice',
    FETCH_DEVICES = 'fetchDevices',
    CREATE_DEVICE = 'createDevice',
    UPDATE_DEVICE = 'updateDevice',
    DELETE_DEVICE = 'deleteDevice',

    MASS_UPDATE_DEVICE = 'massUpdateDevice',

    FETCH_API_DEVICE = 'fetchApiDevice',
    UPDATE_API_DEVICE = 'updateApiDevice'
}

export enum EDeviceMutations {
    SET_DEVICES = 'setDevices',
    SET_DEVICE = 'setDevice',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
