<script setup lang="ts">
import { ref } from 'vue'
import IconItem from '../Icon/IconItem.vue'
import StackLayout from '../Layouts/StackLayout.vue'

type Props = {
    open?: boolean
    withIcon?: boolean
    index?: string | number
}
const props = defineProps<Props>()

const isOpen = ref(props.open || false)
const handleIsOpen = (value: boolean) => (isOpen.value = value)
</script>

<template>
    <b-collapse
        v-model="isOpen"
        :class="`card ${isOpen ? 'collapse-open' : ''}`"
        @open="handleIsOpen(true)"
        @close="handleIsOpen(false)"
        :aria-id="`contentIdForA11y4${props.index}`"
        animation="slide">
        <template #trigger="props">
            <StackLayout
                :gap="8"
                class="collapse-title"
                :aria-id="`contentIdForA11y4${props.index}`"
                :aria-expanded="props.open">
                <slot name="title" />
                <IconItem
                    v-if="withIcon"
                    :icon="props.open ? 'chevron-up' : 'chevron-down'"
                    class="collapse-icon" />
            </StackLayout>
        </template>
        <slot name="content" />
    </b-collapse>
</template>

<style scoped lang="scss">
.collapse-open {
    & :deep(.card-header-title) {
        border-bottom: 1px solid $primary;
    }
}
</style>
