import i18n from '@/locales/i18n'
import deviceApi from '@/services/device.service'
import { EStoreModules } from '@/store/storeType'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { DeviceCreateModel } from '@/types/models/DeviceCreateModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import type { ExtendedPrimitives } from '../../types/ExtendedPrimitives'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EDeviceActions, EDeviceMutations, type DeviceState } from './DeviceStoreTypes'

const state: Ref<DeviceState> = ref({
    data: [],
    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    isLoading: false
})

const getters = {
    getDevice: () => state.value.device,
    getDevices: () => state.value.data,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [EDeviceActions.FETCH_DEVICE](
        { commit }: { commit: Commit },
        { deviceId, noDocumentTitle }: { deviceId: number; noDocumentTitle: boolean }
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .getDevice(deviceId)
            .then(resp => {
                commit(EDeviceMutations.SET_DEVICE, resp.data)
                if (!noDocumentTitle)
                    setDocumentAndTitle(resp.data.name, resp.data.device_type_name)
                return resp.data
            })
            .finally(() => commit(EDeviceMutations.SET_LOADING, false))
    },

    async [EDeviceActions.FETCH_API_DEVICE](
        { commit }: { commit: Commit },
        { deviceId, noDocumentTitle }: { deviceId: number; noDocumentTitle: boolean }
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .getApiDevice(deviceId)
            .then(resp => {
                commit(EDeviceMutations.SET_DEVICE, resp.data)
                if (!noDocumentTitle)
                    setDocumentAndTitle(resp.data.name, resp.data.device_type_name)
                return resp.data
            })
            .finally(() => commit(EDeviceMutations.SET_LOADING, false))
    },

    async [EDeviceActions.FETCH_DEVICES](
        { commit }: { commit: Commit },
        params: Partial<PaginationType>
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .getDevices(params)
            .then(data => {
                commit(EDeviceMutations.SET_DEVICES, data.data)
                commit(EDeviceMutations.SET_TOTAL, data)
            })
            .finally(() => {
                commit(EDeviceMutations.SET_LOADING, false)
            })
    },

    async [EDeviceActions.CREATE_DEVICE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        deviceData: DeviceCreateModel
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .createDevice(deviceData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: deviceData.serial_number ?? deviceData.name,
                    key: EDeviceActions.CREATE_DEVICE
                })
                return resp.data
            })
            .finally(() => commit(EDeviceMutations.SET_LOADING, false))
    },

    async [EDeviceActions.UPDATE_DEVICE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { deviceId, deviceData }: { deviceId: number; deviceData: DeviceModel }
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .updateDevice(deviceId, deviceData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: deviceData.serial_number ?? deviceData.name,
                    key: EDeviceActions.UPDATE_DEVICE
                })
                return resp.data
            })
            .finally(() => commit(EDeviceMutations.SET_LOADING, false))
    },

    async [EDeviceActions.UPDATE_API_DEVICE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { deviceId, deviceData }: { deviceId: number; deviceData: DeviceModel }
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .updateApiDevice(deviceId, deviceData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: deviceData.serial_number ?? deviceData.name,
                    key: EDeviceActions.UPDATE_DEVICE
                })
                return resp.data
            })
            .finally(() => commit(EDeviceMutations.SET_LOADING, false))
    },

    async [EDeviceActions.DELETE_DEVICE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { deviceId, name }: { deviceId: number; name: string }
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .deleteDevice(deviceId)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: EDeviceActions.DELETE_DEVICE
                })
            )
            .finally(() => commit(EDeviceMutations.SET_LOADING, false))
    },

    async [EDeviceActions.MASS_UPDATE_DEVICE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        {
            deviceIds,
            config
        }: { deviceIds: number[]; config: { [key: string]: ExtendedPrimitives | undefined } }
    ) {
        commit(EDeviceMutations.SET_LOADING, true)
        return await deviceApi
            .massUpdateDevice(deviceIds, config)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    key: EDeviceActions.MASS_UPDATE_DEVICE
                })
                return resp.data
            })
            .finally(() => commit(EDeviceMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`device.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`device.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
    }
}

const mutations = {
    [EDeviceMutations.SET_DEVICES](_: DeviceState, devices: DeviceModel[]) {
        state.value.data = devices
    },
    [EDeviceMutations.SET_DEVICE](_: DeviceState, device: DeviceModel) {
        state.value.device = device
    },
    [EDeviceMutations.SET_TOTAL](_: DeviceState, data: PaginationDataType<DeviceModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
        state.value.per_page = data.per_page
    },
    [EDeviceMutations.SET_LOADING](_: DeviceState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
