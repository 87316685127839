<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import type { EventModel } from '@/types/models/EventModel'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import RadioButton from '../../../../components/Buttons/RadioButton.vue'
import DateTimePickerField from '../../../../components/Fields/DateTimePickerField.vue'
import InputField from '../../../../components/Fields/InputField.vue'
import StackLayout from '../../../../components/Layouts/StackLayout.vue'
import { EEventActions } from '../../../../store/eventStore/EventStoreTypes'
import { type SelectorObject } from '../../../../types/components/SelectorObject'

type Props = {
    data: EventModel
}
const props = defineProps<Props>()

type Emits = {
    (e: 'refresh'): void
}
defineEmits<Emits>()

const { t } = useI18n()

const open = ref(false)

const guntimes = ref<{ name?: string; start_at?: Date }[]>([{}])
const guntimeIsNow = ref<boolean>()

const handleConfirm = () =>
    store.dispatch(`${EStoreModules.EVENT}/${EEventActions.SET_GUNTIME}`, {
        data: { guntimes: guntimes.value.filter(item => item.name && item.start_at) },
        eventId: props.data.id
    })

const guntimesOptions = computed<SelectorObject[]>(() => [
    {
        name: t('event.actions.guntime.now'),
        value: 1
    },
    {
        name: t('event.actions.guntime.multipleGuntimes'),
        value: 0
    }
])
</script>

<template>
    <PrimaryButton :label="$t('event.actions.guntime.addGuntimeTitle')" @click="open = true" />
    <BaseDialog
        :title="$t('event.actions.guntime.addGuntimeTitle')"
        :open="open"
        @confirm="handleConfirm"
        @close="open = false"
        class="guntime-component">
        <template #content>
            <StackLayout direction="column" :gap="8">
                <RadioButton
                    :modelValue="guntimeIsNow ? 1 : 0"
                    :options="guntimesOptions"
                    @change="guntimeIsNow = !!$event" />
                <template v-if="!guntimeIsNow">
                    <StackLayout v-for="(item, key) in guntimes" :key="key" :gap="8">
                        <InputField
                            :label="$t('event.actions.guntime.name')"
                            :modelValue="item?.name"
                            @change="guntimes[key] = { ...guntimes[key], name: $event }" />
                        <DateTimePickerField
                            :label="$t('event.actions.guntime.startAt')"
                            :modelValue="item?.start_at"
                            class="guntime-datepicker"
                            @change="guntimes[key] = { ...guntimes[key], start_at: $event }" />
                    </StackLayout>
                    <PrimaryButton
                        :label="$t('event.actions.guntime.addGuntime')"
                        @click="guntimes.push({})"
                        class="fit-content" />
                </template>
                <InputField
                    v-else
                    :label="$t('event.actions.guntime.name')"
                    :modelValue="guntimes[0]?.name"
                    @change="guntimes[0] = { ...guntimes[0], name: $event }" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.guntime-component {
    & :deep(.card-content) {
        position: relative;
        overflow: auto;
    }
}

.guntime-datepicker {
    & :deep(.datepicker) {
        & .dropdown-content {
            position: fixed;
            display: flex;
            flex-flow: wrap;
        }
        & .timepicker {
            & .dropdown-content {
                position: relative;
                flex-flow: unset;
            }
        }
    }
}
</style>
