<script setup lang="ts">
import { computed, ref, watch } from 'vue'

type Props = {
    maxItemPerRow?: number
    gap?: number
    rowGap?: number
}
const props = defineProps<Props>()
const calculatedMaxItemPerRow = ref(props.maxItemPerRow ?? 1)
watch(
    () => props.maxItemPerRow,
    newValue => (calculatedMaxItemPerRow.value = newValue ?? 1)
)

const calculatedGap = computed(() => (props.gap ? `${props.gap}px` : ''))
const calculatedRowGap = computed(() => (props.rowGap ? `${props.rowGap ?? props.gap}px` : ''))
</script>

<template>
    <div :class="`grid-class ${calculatedMaxItemPerRow ? 'max-row' : ''}`">
        <slot />
    </div>
</template>

<style scoped lang="scss">
.grid-class {
    display: grid;
    grid-gap: v-bind(calculatedGap);
    row-gap: v-bind(calculatedRowGap);
}

.max-row {
    grid-template-columns: repeat(v-bind(calculatedMaxItemPerRow), 1fr);
}
</style>
